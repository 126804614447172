.area {
    /* background-color: red; */
    padding: 0;
    margin: 0;
    text-align: left;
    /* background-color: #B1D4E030; */
    position: relative;
    height: max(35vh, 400px);
    padding-bottom: 50px;
}

.intro_background {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    object-position: 50% 15%;
    filter: grayscale(20%);
    opacity: 0.4;
}


.intro_content {
    padding-top: 90px;
    /* padding-bottom: 10vh; */
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    justify-content: center;
    width: 90vw;
    min-height: 300px;
    z-index: 0;
    position: absolute;
}

.intro_action_button {
    margin-top: 2vh;
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.intro_action_button button {
    border: none;
    width: 140px;
    height: 30px;
    font-size: 14px;
    background-color: #182848;
    border-radius: 0.3rem;
    color: white;
    font-weight: 300;
}



.intro_action_button button:hover{
    background-color: #104d86;
    transform: scale(1.03);
    /* transition-duration: 500ms; */
}

.intro_content .intro_main {
    font-weight: 500;
    margin-bottom: 0;
    color: #182848;
    
}

.intro_content .intro_character {
    color: #182848;
    font-weight: 800;
    font-size: 18px;
}

.intro_content .intro_desc {
    margin-top: 1vh;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width: 900px) {
    .intro_content .desc {
        font-size: 14px;
    }
}