.skills {
    background-color: #B1D4E030;
    padding-top: 8vh;
    padding-bottom: 5vh;
    text-align: left;
    padding-left: 5vw;
    
}

.skills .title {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 3vh;
    font-weight: 800;
    color: gray;
    letter-spacing: 14px;
}

.skills .skill_section {
    display: flex;
    flex-direction: row;
}

.skills .skill_section .icon_img {
    /* width: 30px; */
    height: 50px;
    /* position: absolute; */
}

.skills .skill_section .text_area {
    display: flex;
    flex-direction: column;
}

.skills .category {
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 1vh;
    padding-left: 5vw;
    color: #182848;
    /* width: ; */

}

.skills .desc {
    padding-bottom: 5vh;
    font-size: 16px;
    padding-left: 5vw;
}

.skills .desc, .title, .category {
    /* width: 90vw; */
}

@media screen and (max-width: 900px) {
    .skills .desc {
        font-size: 14px;
    }
}