.project_card {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 260px;
    height: 310px;
    overflow: hidden;
    background-color: transparent;
    margin-bottom: 3vh;
}

div.project_card:hover {
    transform: scale(1.02);
    background-color: #2E8BC020;
    /* border: 0.5px solid #2E8BC050; */
}

.project_card .text_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 43%;
}

.project_card .text_title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: min(1vh, 30px);
    padding-top: min(2vh, 30px);
    color: #182848;
}


.project_card .desc {
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 90%;
    line-height: 20px;
    /* display: none; */
}



.project_card div.desc:hover {
    opacity: 1;
}



.project_card .card_image {
    width: 93%;
    height: 53%;
    position: absolute;
    top: 2%;
    object-fit: cover;
    object-position: top;
    opacity: 0.7;
    border-radius: 0.5rem;
}

div.project_card:hover .card_image {
    opacity: 1;
}

 
@media screen and (max-width: 600px) {
    
    .project_card {
        width: 350px;
        height: 360px;
    }
    
    .project_card .text_area {
        height: 30%;
    }

    .project_card .card_image {
        height: 65%;
    }
}

@media screen and (min-width: 1500px) {
    
    .project_card {
        width: min(20vw, 400px);
        height: 330px;
        margin-right: 3vw;
    }

    .project_card .text_area {
        height: 30%;
    }

    .project_card .card_image {
        height: 65%;
    }
    
}