.navbar {
    background: #18284810;
    /* background: transparent; */
    /* background: linear-gradient(120deg, #182848 20%, #34559c 90%); */
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.logo {
    color: #182848;
    font-size: 50px;
    font-weight: 100;
    margin-left: 5vw;
    text-decoration: none;
    /* position: absolute; */
    /* justify-self: flex-start; */
}


#open .logo {
    display: none;
}

.links {
    display: flex;
    /* align-self: flex-end; */
    justify-content: space-evenly;
    align-items: center;
    flex-direction: horizontal;
    height: 100%;
    margin-left: min(15vw, 1200px);
    width: min(53vw, 550px);

}

.links a {
    color: #182848;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    /* margin-right: max(12px, 3vw); */
}

.links a:hover {
    color: #B1D4E0;
    /* margin-right: max(12px, 3vw); */
}

#open {
    background: #182848;
    height: 100vh;
    width: 70vw;
    margin-left: 30vw;
}

#open .links {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#open .links a{
    color: white;
}

#open .links a {
    padding-bottom: 40px;
}

.language {
    /* margin-left: 28vw; */
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    background-color: #ffffff60;
    color: #0C2D48;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 3vw;
    /* width: 60px; */
}

.language .language_flag {
    width: 20px;
    padding-right: 5px;
}

.language:hover {
    color: white;
    background-color: #ffffff80;
}

#open .language {
    display: none;
}

.toggleButton {
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.toggleButton button{
    background-color: transparent;
    border: none;
    color: #182848;
    cursor: pointer;
    margin-right: 10px;
}

.toggleButton svg {
    font-size: 40px;
    height: 100%;
}

#open .toggleButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 10vh;
}

@media screen and (min-width: 600px) {
    #close .toggleButton {
        display: none;
    }
}


@media screen and (max-width: 900px) {

    .links a {
        font-size: 14px;
    }
    

}

@media screen and (max-width: 600px) {
    #close .links {
        display: none;
    }

    .language {
        margin-left: min(50vw, 150px);
        margin-right: 0;
    }
}