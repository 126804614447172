.projects {
    background-color: #145DA015;
    padding-top: 8vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
}

.projects_title {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: left;
    font-weight: 800;
    color: gray;
    letter-spacing: 14px;
}

.projects_panel {
    padding-top: 3vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1200px) {
    .projects_panel {
        padding-top: 3vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .projects_panel {
        padding-top: 3vh;
        padding-right: 5vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

    }
}

@media screen and (max-width: 600px) {
    .projects_panel {
        padding-top: 3vh;
        padding-right: 5vw;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;

    }
}