body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
.navbar {
    background: #18284810;
    /* background: transparent; */
    /* background: linear-gradient(120deg, #182848 20%, #34559c 90%); */
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.logo {
    color: #182848;
    font-size: 50px;
    font-weight: 100;
    margin-left: 5vw;
    text-decoration: none;
    /* position: absolute; */
    /* justify-self: flex-start; */
}


#open .logo {
    display: none;
}

.links {
    display: flex;
    /* align-self: flex-end; */
    justify-content: space-evenly;
    align-items: center;
    flex-direction: horizontal;
    height: 100%;
    margin-left: min(15vw, 1200px);
    width: min(53vw, 550px);

}

.links a {
    color: #182848;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    /* margin-right: max(12px, 3vw); */
}

.links a:hover {
    color: #B1D4E0;
    /* margin-right: max(12px, 3vw); */
}

#open {
    background: #182848;
    height: 100vh;
    width: 70vw;
    margin-left: 30vw;
}

#open .links {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#open .links a{
    color: white;
}

#open .links a {
    padding-bottom: 40px;
}

.language {
    /* margin-left: 28vw; */
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    background-color: #ffffff60;
    color: #0C2D48;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 3vw;
    /* width: 60px; */
}

.language .language_flag {
    width: 20px;
    padding-right: 5px;
}

.language:hover {
    color: white;
    background-color: #ffffff80;
}

#open .language {
    display: none;
}

.toggleButton {
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.toggleButton button{
    background-color: transparent;
    border: none;
    color: #182848;
    cursor: pointer;
    margin-right: 10px;
}

.toggleButton svg {
    font-size: 40px;
    height: 100%;
}

#open .toggleButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 10vh;
}

@media screen and (min-width: 600px) {
    #close .toggleButton {
        display: none;
    }
}


@media screen and (max-width: 900px) {

    .links a {
        font-size: 14px;
    }
    

}

@media screen and (max-width: 600px) {
    #close .links {
        display: none;
    }

    .language {
        margin-left: min(50vw, 150px);
        margin-right: 0;
    }
}
.home {
    /* padding-top: 80px; */
}


/* https://www.canva.com/colors/color-palettes/mermaid-lagoon/ */
.area {
    /* background-color: red; */
    padding: 0;
    margin: 0;
    text-align: left;
    /* background-color: #B1D4E030; */
    position: relative;
    height: max(35vh, 400px);
    padding-bottom: 50px;
}

.intro_background {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    object-position: 50% 15%;
    -webkit-filter: grayscale(20%);
            filter: grayscale(20%);
    opacity: 0.4;
}


.intro_content {
    padding-top: 90px;
    /* padding-bottom: 10vh; */
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    justify-content: center;
    width: 90vw;
    min-height: 300px;
    z-index: 0;
    position: absolute;
}

.intro_action_button {
    margin-top: 2vh;
    width: 300px;
    display: flex;
    justify-content: space-between;
}

.intro_action_button button {
    border: none;
    width: 140px;
    height: 30px;
    font-size: 14px;
    background-color: #182848;
    border-radius: 0.3rem;
    color: white;
    font-weight: 300;
}



.intro_action_button button:hover{
    background-color: #104d86;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    /* transition-duration: 500ms; */
}

.intro_content .intro_main {
    font-weight: 500;
    margin-bottom: 0;
    color: #182848;
    
}

.intro_content .intro_character {
    color: #182848;
    font-weight: 800;
    font-size: 18px;
}

.intro_content .intro_desc {
    margin-top: 1vh;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width: 900px) {
    .intro_content .desc {
        font-size: 14px;
    }
}
.footer {
    /* background: #182848; */
    background: linear-gradient(90deg, #34559c 20%, #182848 100%);
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.project_card {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 260px;
    height: 310px;
    overflow: hidden;
    background-color: transparent;
    margin-bottom: 3vh;
}

div.project_card:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    background-color: #2E8BC020;
    /* border: 0.5px solid #2E8BC050; */
}

.project_card .text_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 43%;
}

.project_card .text_title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: min(1vh, 30px);
    padding-top: min(2vh, 30px);
    color: #182848;
}


.project_card .desc {
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 90%;
    line-height: 20px;
    /* display: none; */
}



.project_card div.desc:hover {
    opacity: 1;
}



.project_card .card_image {
    width: 93%;
    height: 53%;
    position: absolute;
    top: 2%;
    object-fit: cover;
    object-position: top;
    opacity: 0.7;
    border-radius: 0.5rem;
}

div.project_card:hover .card_image {
    opacity: 1;
}

 
@media screen and (max-width: 600px) {
    
    .project_card {
        width: 350px;
        height: 360px;
    }
    
    .project_card .text_area {
        height: 30%;
    }

    .project_card .card_image {
        height: 65%;
    }
}

@media screen and (min-width: 1500px) {
    
    .project_card {
        width: min(20vw, 400px);
        height: 330px;
        margin-right: 3vw;
    }

    .project_card .text_area {
        height: 30%;
    }

    .project_card .card_image {
        height: 65%;
    }
    
}
.projects {
    background-color: #145DA015;
    padding-top: 8vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
}

.projects_title {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: left;
    font-weight: 800;
    color: gray;
    letter-spacing: 14px;
}

.projects_panel {
    padding-top: 3vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1200px) {
    .projects_panel {
        padding-top: 3vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 900px) {
    .projects_panel {
        padding-top: 3vh;
        padding-right: 5vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

    }
}

@media screen and (max-width: 600px) {
    .projects_panel {
        padding-top: 3vh;
        padding-right: 5vw;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;

    }
}
.skills {
    background-color: #B1D4E030;
    padding-top: 8vh;
    padding-bottom: 5vh;
    text-align: left;
    padding-left: 5vw;
    
}

.skills .title {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 3vh;
    font-weight: 800;
    color: gray;
    letter-spacing: 14px;
}

.skills .skill_section {
    display: flex;
    flex-direction: row;
}

.skills .skill_section .icon_img {
    /* width: 30px; */
    height: 50px;
    /* position: absolute; */
}

.skills .skill_section .text_area {
    display: flex;
    flex-direction: column;
}

.skills .category {
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 1vh;
    padding-left: 5vw;
    color: #182848;
    /* width: ; */

}

.skills .desc {
    padding-bottom: 5vh;
    font-size: 16px;
    padding-left: 5vw;
}

.skills .desc, .title, .category {
    /* width: 90vw; */
}

@media screen and (max-width: 900px) {
    .skills .desc {
        font-size: 14px;
    }
}
.chart_section {
    background-color: #B1D4E030;
    padding-top: calc(80px + 5vh);
    padding-bottom: 5vh;
    text-align: left;
    padding-left: 5vw;
}

.chart_title {
    font-size: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
    font-weight: 800;
    color: #0C2D48;
    letter-spacing: 12px;
    padding-bottom: 3vh;
}

.chart_charts {
    padding-top: 3vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.chart_card {
    background-color: #80808015;
    margin: 1%;
    margin-bottom: 3vh;
    border-radius: 1rem;
    width: 45vw;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0; */
}

.chart_graph {
    width: 100% !important;
    height: 350px !important;
}


@media screen and (max-width: 1200px) {
    .chart_card {
        width: 90vw;
    }
}

@media screen and (max-width: 600px) {
    .chart_graph {
        height: 300px !important;
    }

    .chart_card {
        height: 300px;
    }
}
.contact_background {
    /* background-color: red; */
    padding: 0;
    margin: 0;
    text-align: left;
    background-color: #B1D4E030;
    position: relative;
    height: max(20vh, 220px);
    padding-top: 8vh;
    padding-bottom: 8vh;
    padding-left: 5vw;
}

.contact_area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contact_title {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 3vh;
    font-weight: 800;
    color: gray;
    letter-spacing: 14px;
}

.contact_main {
    display: flex;
    flex-direction: column;
}

.contact_item {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    align-items: center;
    margin-bottom: 15px;
}

.contact_item_icon {
    width: 30px;
    margin-left: 5px;
    margin-right: 5vw;
}

.contact_item a {
    text-decoration: none;
    color: black;
}

.contact_item a:hover {
    color: #145DA0;
}
