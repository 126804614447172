.chart_section {
    background-color: #B1D4E030;
    padding-top: calc(80px + 5vh);
    padding-bottom: 5vh;
    text-align: left;
    padding-left: 5vw;
}

.chart_title {
    font-size: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
    font-weight: 800;
    color: #0C2D48;
    letter-spacing: 12px;
    padding-bottom: 3vh;
}

.chart_charts {
    padding-top: 3vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}