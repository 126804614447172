.App {
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}